$(document).ready(function () {
    $(document.body).on('click', '[data-href]', function () {
        console.log('click', $(this).data('href'));
        location.href = $(this).data('href');
    });

    $(document.body).on('submit', 'form.ajax', function (event) {
        event.preventDefault();

        var $form = $(this);
        var url = $form.attr('action');

		if ($form.data('disabled')) {
			return;
		}

        $.ajax({
            url: url,
            type: $form.attr('method'),
            data: $form.serialize()
        }).done(function (response) {
            var $response = $(response);
            if ($response.length > 0) {
                $form.replaceWith($response);
                $form = $response;
            }
            $form.trigger('ajax.completed', [response, url]);
        }).fail(function (xhr) {
            var $response = $(xhr.responseText);
            if ($response.length > 0) {
                $form.replaceWith($response);
                $form = $response;
            }
            $form.trigger('ajax.completed', [response, url]);
        });
    });

    function loadAndReplace(target, url) {
        target = $(target);
        $.get(url).done(function (response) {
            target.html(response);
            target.trigger('ajax.completed', [response, url]);
        });
    }

    window.AjaxReplace = {
        loadAndReplace: loadAndReplace
    };

    $(document.body ).on('click', '*[data-ajax-target]', function (event) {
        event.preventDefault();
        var target = $($(this).data('ajax-target'));
        var url = $(this).attr('href');
        $.ajax({
            url: url,
            type: $(this).data('ajax-method') || 'GET'
        }).done(function (response) {
            target.html(response);
            target.trigger('ajax.completed', [response, url]);
        });
    });

    $(document.body).on('ajax.completed', '.modal, .modal *', function (event, response) {
        $(this).closest('.modal').modal({backdrop: 'static'}).modal(response ? 'show' : 'hide');
        if (!response) location.reload();
    });

    $(document.body).on('click', '.attachments .attachment .remove', function (event) {
        $(event.currentTarget).closest('.attachment' ).remove();
    });

    $(document.body).on('click', '.attachments .retry', function (event) {
        $(event.target).closest('.attachment').remove();
        $('.attachments .upload input').click();
    });

    $(document.body).on('change', '.attachments .upload', function (event) {
        var $input = $(event.target);
        var formData = new FormData();
        var file = $input.get(0).files[0];

        var index = Math.floor((1 + Math.random()) * 10000000);
        var el = $($('#attachmentTemplate').html().replace('{name}', file.name).replace('{index}', index)).addClass('uploading');
        //var el = $('<div class="attachment"><span class="glyphicon glyphicon-file"></span>'+file.name+' <a class="remove">remove</a></div>').addClass('uploading');
        $(event.currentTarget).append(el);
        var url = '/documents/create';
        formData.append('data', file);

        $(event.currentTarget).find()

		var $form = $input.closest('form');
		$form.data('disabled', 'disabled');
        $form.attr('data-disabled', 'disabled');
        $input.replaceWith($input.clone());

        $.ajax( {
            url : url,
            type : 'POST',
            data : formData,
            cache: false,
            contentType: false,
            processData: false
        }).fail(function (response) {
			//el.remove();
            el.replaceWith($('#attachmentErrorTemplate').html().replace('{name}', file.name));
		}).done(function (response) {
			el.append($('<input type="hidden" name="attachments['+index+'].uuid" value="'+response.uuid+'" />'));
		}).always(function (response) {
            el.removeClass('uploading');
			$form.data('disabled', null);
            $form.attr('data-disabled', null);
        });
    });

    // Fix alignment of labels of static form elements
    $("p.form-control-static").prev().css("top", "6px");
});