// override setting for BlockUI plugin
$.blockUI.defaults  = _.merge({}, $.blockUI.defaults, {
    // message displayed when blocking (use null for no message)
    message: '<button class="btn btn-lg"><span class="glyphicon glyphicon-refresh glyphicon-refresh-animate"></span> Loading...</button>',
    css: {
        width:  'auto',
        top:    'auto',
        left:   'auto',
        border: 'none'
    }
});